import React, { Component } from "react";
import DocumentTitle from "react-document-title";
import ScrollUp from "react-scroll-up";
import ReactGA from "react-ga";
import MetaTags from "react-meta-tags";
import * as Constants from "../Constants";

class Page extends Component {
  componentDidMount() {
    ReactGA.pageview(window.location.pathname);
  }

  render() {
    var title = this.props.title;

    var shortTitle = this.props.shortTitle;
    if (!shortTitle) {
      shortTitle = title;
    }
    shortTitle += " | " + Constants.siteName;

    var width = this.props.width !== undefined ? this.props.width : "875px";
    var margin = this.props.margin !== undefined ? this.props.margin : "auto";

    let upStyle = {
      position: "fixed",
      bottom: 20,
      right: 20,
      width: "44px",
      height: "44px",
      backgroundColor: "#00413a",
      color: "white",
      textAlign: "center",
      lineHeight: "44px",
      boxShadow: "0px -4px 8px 0px rgba(0, 0, 0, 0.25)",
    };

    return (
      <DocumentTitle title={shortTitle}>
        <div>
          <MetaTags>
            <meta property="og:type" content="website" />
          </MetaTags>
          <div
            className="wrapper"
            style={{
              maxWidth: width,
              marginLeft: margin,
              marginRight: margin,
            }}
          >
            {title && <h1>{title}</h1>}
            {this.props.children}
          </div>
          <ScrollUp showUnder={160} style={upStyle}>
            <span>↑</span>
          </ScrollUp>
        </div>
      </DocumentTitle>
    );
  }
}

export default Page;
