import React, { Component } from "react";
import Page from "../components/Page";
import NewsContainer from "../components/NewsContainer";
import NewsPage from "../components/NewsPage";

class News extends Component {
  constructor() {
    super();
    this.state = { newsItems: [], newsItem: undefined };
  }

  componentDidMount() {
    fetch(process.env.PUBLIC_URL + "/api/news.json")
      .then((response) => {
        return response.json();
      })
      .then((newsItems) => {
        var selectedNewsItem = undefined;
        for (let index = 0; index < newsItems.length; index++) {
          const newsItem = newsItems[index];
          if (newsItem.name === this.props.match.params.article) {
            selectedNewsItem = newsItem;
            break;
          }
        }

        this.setState({
          newsItems: newsItems,
          newsItem: selectedNewsItem,
        });
      });
  }

  render() {
    if (this.state.newsItem !== undefined) {
      return <NewsPage item={this.state.newsItem} />;
    } else {
      return (
        <Page title="Nieuws">
          <NewsContainer items={this.state.newsItems} showIntro={true} />
        </Page>
      );
    }
  }
}

export default News;
