import React, { Component } from "react";
import Page from "../components/Page";
import Card from "../components/Card";
import GridContainer from "../components/GridContainer";

class Authorizations extends Component {
  render() {
    return (
      <Page title="Bevoegdheden">
        <GridContainer>
          <Card
            title="Technische Dienst"
            titleLink="/bevoegdheden/technische-dienst"
            imgSrc="themas/technische_dienst.jpg"
          />
          <Card
            title="Openbare Werken"
            titleLink="/bevoegdheden/openbare-werken"
            imgSrc="themas/openbare_werken.jpg"
          />
          <Card
            title="Landbouw"
            titleLink="/bevoegdheden/landbouw"
            imgSrc="themas/landbouw.jpg"
          />
          <Card
            title="Integraal Waterbeleid"
            titleLink="/bevoegdheden/integraal-waterbeleid"
            imgSrc="themas/integraal_waterbeleid.jpg"
          />
          <Card
            title="Nutsvoorzieningen"
            titleLink="/bevoegdheden/nutsvoorzieningen"
            imgSrc="themas/nutsvoorzieningen.jpg"
          />
          <Card
            title="Grondbeleid"
            titleLink="/bevoegdheden/grondbeleid"
            imgSrc="themas/grondbeleid.jpg"
          />
          <Card
            title="Omgevingsvergunningen voor landbouw en bedrijven"
            titleLink="/bevoegdheden/omgevingsvergunningen"
            imgSrc="themas/omgevingsvergunningen.jpg"
          />
          <Card
            title="Senioren"
            titleLink="/bevoegdheden/senioren"
            imgSrc="themas/senioren.jpg"
          />
          <Card hidden={true} />
        </GridContainer>
      </Page>
    );
  }
}

export default Authorizations;
