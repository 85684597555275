import React, { Component } from "react";
import Page from "../components/Page";
import ContactInfo from "../components/ContactInfo";
import { css } from "glamor";
import AddressInfo from "../components/AddressInfo";

class Contact extends Component {
  render() {
    let containerCss = css({
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-between",
    });

    let itemCss = css(
      {
        flex: "0 1 auto",
        width: "calc(50% - 1em)",
      },
      {
        "@media(max-width: 640px)": {
          flex: "0 1 auto",
          width: "100%",
        },
      }
    );

    return (
      <Page title="Contacteer Me" shortTitle="Contact">
        <div className={containerCss}>
          <div className={itemCss}>
            <img
              alt="Woning en Huisvesting"
              src={process.env.PUBLIC_URL + "/images/claudio.jpg"}
              className="fullwidth shadow"
            />
          </div>
          <div className={itemCss}>
            <p>
              Ik hou ervan om in dialoog te gaan met mensen en ben dan ook
              benieuwd naar al jullie vragen, suggesties en bedenkingen.
            </p>
            <p> Aarzel dan zeker niet om contact met mij op te nemen.</p>
            <p>Tot binnenkort?</p>
            <p>Claudio</p>
            <h2>Contactgegevens</h2>
            <AddressInfo />
            <ContactInfo />
          </div>
        </div>
      </Page>
    );
  }
}

export default Contact;
