import React, { Component } from "react";
import Page from "../../components/Page";

class Nutsvoorzieningen extends Component {
  render() {
    return (
      <Page title="Nutsvoorzieningen">
        <p>
          <img
            alt="Nutsvoorzieningen"
            src={
              process.env.PUBLIC_URL + "/images/themas/nutsvoorzieningen.jpg"
            }
            className="fullwidth shadow"
          />
        </p>

        <p>
          De verleddingsgraad in Beernem bedraagt momenteel 47%. Eind 2025 zal
          dit 78% bedragen.
        </p>
        <p>
          Medio 2025 zullen al onze hoofdwegen beschikken over led-verlichting,
          vanaf dan zullen we onze hoofdwegen 's nachts niet meer doven maar
          dimmen.
        </p>
      </Page>
    );
  }
}

export default Nutsvoorzieningen;
