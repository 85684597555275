import React, { Component } from "react";
import Page from "../../components/Page";

class Senioren extends Component {
  render() {
    return (
      <Page title="Senioren">
        <p>
          <img
            alt="Senioren"
            src={process.env.PUBLIC_URL + "/images/themas/senioren.jpg"}
            className="fullwidth shadow"
          />
        </p>
        <p>
          In eerste instantie moeten we aandacht hebben voor de zorg die
          senioren nodig hebben. Hiervoor moet de gemeente nauw samen werken met
          de verschillende welzijnsactoren actief binnen de gemeente.
        </p>
        <p>
          De seniorenraad speelt een centrale rol in het gemeentelijk beleid. In
          het meerjarenplan moeten we aandacht hebben voor de verschillende
          domeinen die belangrijk zijn voor onze senioren, zoals huisvesting,
          bewegen (sport), cultuur en recreatie, mobiliteit, toegankelijkheid en
          zorg.
        </p>
      </Page>
    );
  }
}

export default Senioren;
