import React from "react";
import { Switch, Route } from "react-router-dom";
import Page from "./Page";
import Home from "../views/Home";
import News from "../views/News";
import Authorizations from "../views/Authorizations";
import TechnischeDienst from "../views/authorizations/TechnischeDienst";
import OpenbareWerken from "../views/authorizations/OpenbareWerken";
import Landbouw from "../views/authorizations/Landbouw";
import IntegraalWaterbeleid from "../views/authorizations/IntegraalWaterbeleid";
import WhoAmI from "../views/WhoAmI";
import Contact from "../views/Contact";
import Links from "../views/Links";
import Nutsvoorzieningen from "../views/authorizations/Nutsvoorzieningen";
import Grondbeleid from "../views/authorizations/Grondbeleid";
import Omgevingsvergunningen from "../views/authorizations/Omgevingsvergunningen";
import Senioren from "../views/authorizations/Senioren";

const NoMatch = ({ location }) => (
  <Page title="Oeps, niet gevonden">
    <p>We konden niets vinden voor deze URL:</p>
    <p>
      <code>{location.pathname}</code>
    </p>
    <p>
      <a href="/">Ga terug naar de homepage</a>
    </p>
  </Page>
);

const Main = () => (
  <main>
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/nieuws/:article?" component={News} />
      <Route exact path="/bevoegdheden" component={Authorizations} />
      <Route
        exact
        path="/bevoegdheden/technische-dienst"
        component={TechnischeDienst}
      />
      <Route
        exact
        path="/bevoegdheden/openbare-werken"
        component={OpenbareWerken}
      />
      <Route exact path="/bevoegdheden/landbouw" component={Landbouw} />
      <Route
        exact
        path="/bevoegdheden/nutsvoorzieningen"
        component={Nutsvoorzieningen}
      />
      <Route
        exact
        path="/bevoegdheden/integraal-waterbeleid"
        component={IntegraalWaterbeleid}
      />
      <Route exact path="/bevoegdheden/grondbeleid" component={Grondbeleid} />
      <Route
        exact
        path="/bevoegdheden/omgevingsvergunningen"
        component={Omgevingsvergunningen}
      />
      <Route exact path="/bevoegdheden/senioren" component={Senioren} />
      <Route path="/wie-ben-ik" component={WhoAmI} />
      <Route path="/contact" component={Contact} />
      <Route path="/links" component={Links} />
      <Route component={NoMatch} />
    </Switch>
  </main>
);

export default Main;
