import React, { Component } from "react";
import Page from "../../components/Page";

class IntegraalWaterbeleid extends Component {
  render() {
    return (
      <Page title="Integraal Waterbeleid">
        <p>
          <img
            alt="Integraal Waterbeleid"
            src={
              process.env.PUBLIC_URL +
              "/images/themas/integraal_waterbeleid.jpg"
            }
            className="fullwidth shadow"
          />
        </p>

        <p>
          De uitvoeringsgraad van onze riolering ligt op 93%. Het afvalwater
          afkomstig van de kernen van Beernem, Oedelem en Sint-Joris wordt al
          jaren lang gezuiverd. In het buitengebied is echter nog werk aan de
          winkel.
        </p>
        <p>
          In deze legislatuur werd dan ook nog nooit zoveel geïnvesteerd in de
          sanering van het afvalwater. Volgende straten en omgevingen lozen
          sinds de start van de huidige legislatuur niet meer in beken en
          waterlopen: Sijselestraat, Ruweschuurstraat - Hoogstraat, Oostveld -
          Pluime, Zeldonk, Scherpestraat - Beernemstraat en Zuiddammestraat.
        </p>
        <p>
          De komende zes jaar worden volgende straten aangepakt: Akkerstraat
          -Beverhoutsveldstraat en hun zijstraten, Boereweg, Lijsterhoek en de
          straten die nog niet aangesloten zijn in Sint-Joris.{" "}
        </p>
        <p>
          Uniek voor Vlaanderen is dat Beernem de volledig kosten voor het
          scheiden van afvalwater en regenwater op het privaat terrein volledig
          voor haar rekening neemt.
        </p>
      </Page>
    );
  }
}

export default IntegraalWaterbeleid;
