import React, { Component } from "react";

class AddressInfo extends Component {
  render() {
    return (
      <p>
        Claudio Saelens
        <br />
        Maria-Aaltersteenweg 32
        <br />
        8730 Sint-Joris (Beernem)
      </p>
    );
  }
}

export default AddressInfo;
