import React, { Component } from "react";
import Page from "../../components/Page";

class Landbouw extends Component {
  render() {
    return (
      <Page title="Landbouw">
        <p>
          <img
            alt="Landbouw"
            src={process.env.PUBLIC_URL + "/images/themas/landbouw.jpg"}
            className="fullwidth shadow"
          />
        </p>

        <p>
          Beernem telt 130 landbouwbedrijven. Ze zijn een belangrijke
          economische factor in de gemeente. Daarom is het belangrijk dat
          Beernem zijn eigen landbouwloket heeft. De landbouwraad is een
          belangrijk klankbord voor het beleid.
        </p>
        <p>
          Het Beverhoutsveld moet voorbehouden blijven voor de Beernemse actieve
          landbouwers en mag niet verkocht worden. Landbouwgrond moet
          landbouwgrond blijven.{" "}
        </p>
        <p>We ondersteunen en promoten landbouwverbreding. </p>
        <p>Het subsidiereglement groenbedekkers moet geactualiseerd worden. </p>
        <p>
          Zuiver water is uiterst belangrijk, daarom moeten al onze woningen in
          de gemeente aangesloten worden op een zuiveringsinstallatie.{" "}
        </p>
        <p>
          Op vlak van pesticidenreductie willen we inzitten op het voorkomen van
          puntvuiling.
        </p>
        <p>
          Een aantrekkelijk landelijk weggennet is niet alleen interessant voor
          de recreatieve fietsers, maar onontbeerlijk voor onze
          plattelandsbewoners.{" "}
        </p>
      </Page>
    );
  }
}

export default Landbouw;
