import React, { Component } from "react";
import Card from "../components/Card";
import GridContainer from "../components/GridContainer";
import { v4 as uuidv4 } from "uuid";

class NewsContainer extends Component {
  render() {
    let items = this.props.items ? this.props.items : [];

    if (this.props.limit > 0) {
      items = items.slice(0, this.props.limit);
    }

    let newsItems = items.map((newsItem) => (
      <Card
        key={newsItem.name}
        title={newsItem.title}
        titleLink={`/nieuws/${newsItem.name}`}
        imgSrc={newsItem.image}
        date={newsItem.publishedOn}
      >
        {this.props.showIntro && (
          <p dangerouslySetInnerHTML={{ __html: newsItem.intro }}></p>
        )}
      </Card>
    ));

    let remainder = newsItems.length % 3;
    if (remainder > 0) {
      let emptyToAdd = 3 - remainder;
      for (let i = 0; i < emptyToAdd; i++) {
        newsItems.push(<Card hidden={true} key={uuidv4()} />);
      }
    }

    return <GridContainer>{newsItems}</GridContainer>;
  }
}

export default NewsContainer;
