import React, { Component } from "react";
import Page from "../../components/Page";

class TechnischeDienst extends Component {
  render() {
    return (
      <Page title="Technische Dienst">
        <p>
          <img
            alt="Technische Dienst"
            src={
              process.env.PUBLIC_URL + "/images/themas/technische_dienst.jpg"
            }
            className="fullwidth shadow"
          />
        </p>

        <p>
          De focus van de gemeentelijke technische buitendienst moet liggen op
          het onderhoud van wegen, groenperken en gemeentelijke gebouwen. Een
          goede uitrusting is belangrijk! Daarom hebben we een inhaalbeweging
          gemaakt op het vlak van machines en wagenpark.
        </p>
        <p>
          In Beernem hebben we de traditie om heel veel zaken in eigen beheer
          uit te voeren, dit zorgt er voor dat we vlug kunnen schakelen bij
          dringende zaken.
        </p>
        <p>
          Voor specifieke taken kan er samengewerkt worden met externe partners.
          Bevoorrechte partners zijn het{" "}
          <a
            href="http://www.lti-oedelem.be"
            target="_blank"
            rel="noopener noreferrer"
          >
            Land en Tuinbouw Instituut
          </a>{" "}
          (Oedelem), lokale landbouwers, ondernemers en actoren binnen de
          sociale economie.
        </p>
      </Page>
    );
  }
}

export default TechnischeDienst;
