import React, { Component } from "react";

class ContactInfo extends Component {
  render() {
    return (
      <p>
        <nobr>
          Telefoon: <a href="callto:+32 50 68 56 93">+32 50 68 56 93</a>
        </nobr>
        <br />
        <nobr>
          GSM: <a href="callto:+32 496 70 44 60">+32 496 70 44 60</a>
        </nobr>
        <br />
        <nobr>
          Email:{" "}
          <a href="mailto:claudio@claudiosaelens.be">
            claudio@claudiosaelens.be
          </a>
        </nobr>
      </p>
    );
  }
}

export default ContactInfo;
