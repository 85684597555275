import React, { Component } from "react";
import Header from "./Header";
import Main from "./Main";
import Footer from "./Footer";
import * as Constants from "../Constants";
import { css } from "glamor";

class App extends Component {
  render() {
    css.global(".shadow", {
      boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.25)",
    });
    css.global(".my-social-icon .social-svg", {
      color: "#BBC7CC",
    });

    return (
      <div>
        <Header title={Constants.siteName} />
        <Main />
        <Footer />
      </div>
    );
  }
}

export default App;
