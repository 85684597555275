import React, { Component } from "react";
import Page from "../components/Page";
import WebLinksContainer from "../components/WebLinksContainer";

class Links extends Component {
  render() {
    return (
      <Page title="Links">
        <WebLinksContainer />
      </Page>
    );
  }
}

export default Links;
