import React, { Component } from "react";
import Page from "../components/Page";

class WhoAmI extends Component {
  render() {
    return (
      <Page title="Wie ben ik?">
        <p>
          <img
            alt="Claudio, Eveline, Lowie en Artuur"
            src={process.env.PUBLIC_URL + "/images/gezinsfoto.jpg"}
            className="fullwidth shadow"
          />
        </p>
        <h2>Wie is Claudio?</h2>

        <p>
          Ik ben Claudio (45 jaar) en woon op de Loeihoeve in Sint-Joris samen
          met Eveline Serry, Lowie (15) en Artuur (11). Speelboerderij “De
          Loeihoeve” is een vleesveebedrijf. We telen ook aardappelen en
          organiseren vakantiekampen en verjaardagsfeestjes voor kinderen (
          <a
            href="https://www.deloeihoeve.be"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            www.deloeihoeve.be
          </a>
          ).
        </p>
        <p>
          Ik woon al altijd in Sint-Joris en ging er naar de lagere school. Na
          het middelbaar in Aalter (St-Gerolfinstituut, nu Emmaüsinstituut)
          studeerde ik af als industrieel ingenieur. Nadien volgende nog een
          aanvullende opleiding Ruimtelijke Planning (Ugent).
        </p>
        <p>Sinds 2015 ben ik schepen In Beernem.</p>
        <h2>Politieke Loopbaan</h2>
        <ul>
          <li>
            <p>
              1 januari 2009 - 31 december 2012
              <br />
              OCMW-raadslid Beernem
            </p>
          </li>
          <li>
            <p>
              1 januari 2013 - 31 december 2014
              <br />
              Gemeenteraadslid Beernem
            </p>
          </li>
          <li>
            <p>
              1 januari 2015 - 31 december 2018
              <br />
              Schepen van ruimtelijke ordening, wonen, landbouw, milieu en
              integraal waterbeleid in Beernem
            </p>
          </li>
          <li>
            <p>
              Sinds 1 januari 2019
              <br />
              Schepen van openbare werken, nutsvoorzieningen, integraal
              waterbeleid, grondbeleid, omgevingsvergunningen voor landbouw en
              bedrijven, landbouw en senioren
            </p>
          </li>
        </ul>
        <h2>Waar sta &amp; ga ik voor?</h2>
        <p>
          “10 jaar als schepen meebouwen aan je eigen gemeente is fantastisch.
          Maar het werk is nog niet af. Daarom wil ik samen met jouw steun
          verder bouwen aan Beernem.“
        </p>
        <p>
          <b>Kies Claudio, kies voor:</b>
        </p>
        <ul>
          <li>
            <p>
              <b>Landbouw</b>
              <br />
              Onze landbouwers krijgen mijn steun.
            </p>
          </li>
          <li>
            <p>
              <b>Verkeersveiligheid</b>
              <br />
              Onze woonkernen moeten verbonden worden met veilige fietspaden.
              Onderhoud van ons wegennet en voetpaden zijn mijn speerpunten voor
              de komende 6 jaar.
            </p>
          </li>
          <li>
            <p>
              <b>Zuiver Water</b>
              <br />
              Een 100% zuivering van ons afvalwater is de enige garantie op
              propere waterlopen. Onze uitvoeringsgraad zit op 93%. Het einddoel
              is in zicht.
            </p>
          </li>
          <li>
            <p>
              <b>Plezier</b>
              <br />
              Met een sterk uitgebouwde infrastructuur (jeugdlokalen,
              ontmoetingscentra,…) wil ik het verenigingsleven ondersteunen.
            </p>
          </li>
        </ul>
      </Page>
    );
  }
}

export default WhoAmI;
