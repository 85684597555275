import React, { Component } from "react";
import Page from "../../components/Page";

class OpenbareWerken extends Component {
  render() {
    return (
      <Page title="Openbare Werken">
        <p>
          <img
            alt="Openbare Werken"
            src={process.env.PUBLIC_URL + "/images/themas/openbare_werken.jpg"}
            className="fullwidth shadow"
          />
        </p>
        <p>
          Bij openbare werken ligt de focus op waterzuivering en aanleg van
          veilige vrijliggende fietspaden. We hebben de ambitie om:
        </p>
        <ul>
          <li>100% van ons afvalwater te zuiveren </li>
          <li>
            veilige fietspaden aan te leggen tussen al onze woonkernen en
            buurgemeenten.
          </li>
          <li>Al onze verbindingswegen in beton te vervangen door asfalt.</li>
        </ul>
        <p>
          Daarnaast moeten we aandacht hebben voor herstel en onderhoud van onze
          bestaande voetpaden, woonstraten en landelijke wegen.{" "}
        </p>
      </Page>
    );
  }
}

export default OpenbareWerken;
