import React, { Component } from "react";
import Page from "./Page";
import { css } from "glamor";
import MetaTags from "react-meta-tags";

class NewsPage extends Component {
  componentDidMount() {}

  render() {
    const newsItem = this.props.item;

    let introCss = css({
      fontWeight: "bold"
    });

    let publishedOnCss = css({
      fontSize: "0.75em",
      color: "#333",
      padding: "0px 4px",
      textTransform: "uppercase"
    });

    let rootURL = "https://www.claudiosaelens.be" + process.env.PUBLIC_URL;

    return (
      <Page title={newsItem.title}>
        <MetaTags>
          <meta property="og:title" content={newsItem.title} />
          <meta property="og:description" content={newsItem.intro} />
          <meta
            property="og:url"
            content={rootURL + "/nieuws/" + newsItem.name}
          />
          <meta
            property="og:image"
            content={rootURL + "/images/" + newsItem.image}
          />
        </MetaTags>
        <article>
          <p>
            <img
              src={process.env.PUBLIC_URL + "/images/" + newsItem.image}
              alt={newsItem.title}
              className="fullwidth shadow"
            />
          </p>
          <div
            dangerouslySetInnerHTML={{ __html: newsItem.intro }}
            className={introCss}
          />
          <div dangerouslySetInnerHTML={{ __html: newsItem.text }} />
          <footer className={publishedOnCss}>
            Gepubliceerd op {newsItem.publishedOn}
          </footer>
        </article>
      </Page>
    );
  }
}

export default NewsPage;
